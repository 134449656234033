<template>
  <span></span>
</template>

<script>
import axios from "axios";

export default {
  props: ["text"],
  data() {
    return {
      audioSrc: null,
      load: false,
    };
  },
  methods: {
    async synthesizeSpeech() {
      if (!this.text) return;
      this.loader = true;

      const apiKey = "AIzaSyBsYLB5kYbjmSX1NZo0aPcWUt7iMEyY5cQ"; // API anahtarınızı buraya yazın
      const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
      const requestBody = {
        input: {
          text: this.text,
        },
        voice: {
          languageCode: "tr-TR",
          name: "tr-TR-Wavenet-E",
        },
        audioConfig: {
          audioEncoding: "LINEAR16", // Change the audioEncoding to LINEAR16 for compatibility with Web Audio API
          effectsProfileId: ["small-bluetooth-speaker-class-device"],
          pitch: 4.4,
          speakingRate: 1,
        },
      };
      try {
        const response = await axios.post(url, requestBody);
        const audioContent = response.data.audioContent;
        const audioBuffer = this.base64ToArrayBuffer(audioContent); // Convert the Base64 data to an ArrayBuffer

        const audioContext = new AudioContext();
        const source = audioContext.createBufferSource();
        audioContext.decodeAudioData(audioBuffer, function (buffer) {
          source.buffer = buffer;
          source.connect(audioContext.destination);
          source.start(0);
        });

        this.loader = false;
      } catch (error) {
        console.error("Sesli çıktı oluşturma başarısız oldu:", error);
      }
    },
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    }
  },
};
</script>
