<template>
  <div class="home" :style="'background-image:url(' + require('@/assets/bg.png') + ')'">

    <speechItem :load="load" @changeMessage="(r) => userSendMessage(r)" />
    <textItem :text="speakText" ref="textItem" />
    <!-- <div>
      <p v-for="(item, index) in List" :key="index">
        {{
          Number(item.type) == 1 ? "KULLANICI : " + item.text : "QUANTUM : " + item.text
        }}
      </p>

      <div v-if="load"><i class="fas fa-spinner fa-spin"></i> Düşünüyor</div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import speechItem from "@/components/speechItem.vue";
import textItem from "@/components/textToItem";
import axios from "axios";
export default {
  name: "HomePage",
  components: {
    speechItem,
    textItem,
  },
  data() {
    return {
      List: [],
      speakText: "",
      load: false,
    };
  },
  methods: {
    userSendMessage(val) {
      this.List.push({ tpye: 1, text: val });
      this.getAnswer(val).then((res) => {
        this.List.push({ type: 2, text: res });
      });
    },
    async getAnswer() {
      this.load = true;
      const requestBody = {
        model: "gpt-3.5-turbo",
        temperature: 0.2,
        top_p: 0.1,
        frequency_penalty: 0,
        messages: [
          {
            role: "system",
            content:
             "---- SEN NAR Eğitime ait dijital bir asistansın ----" +
             `Nar Eğitim ve Danışmanlık Merkezi 2002 yılından beri yeni nesil model ve tekniklerle bireysel & kurumsal eğitimler ve danışmanlık hizmetleri sunmaktayız.
             
              ---- Vizyonumuz ----
              Nar Eğitim ve Danışmanlık Merkezi olarak Türkiye ve Türkiye’yi çevreleyen coğrafyada faaliyet gösteren kurumların en çok güvendiği ve tercih ettiği gelişim ortağı olmayı hedefliyoruz. 
              
              ---- Misyonumuz ----   
              Kurumların gelişim ortağı olurken hem bu kurumların ekosistemlerine, hem de kendi çalışanlarımıza sürekli değer katıyoruz. Aklını ve kalbini, kurumların gelişimine adamış eğitimci ve danışmanların da en çok tercih ettiği kurum olmayı amaçlıyoruz.
              
              ---- Temel Prensiplerimiz ----
              Sürdürülebilir Olmak: Eğitim ve Danışmanlık alanında ortaya koyduğumuz sonuçlar ve iş yapma biçimimiz ile hem gelişim ortağı olduğumuz kurumlar, hem de çalışanlarımız için sürdürülebilir olmaya özen gösteririz.
              Basit ve Sade Olmak: İletişim kurmanın en temel kurallarından birinin netlik olduğunu düşünürüz. Bu nedenle eğitimlerimizde, kurum dilimizde ve beden dilimizde basit, sade ve anlaşılır olmaya özen gösteririz.
              Gerçek ve Samimi Olmak: Aynı anda her konunun uzmanı olamayacağımızı biliriz. Bu sebeple, çalıştığımız kurumların ve bireylerin hayatlarına gerçekten katkı sağlayabileceğimiz alanlarda çözüm üretmeyi tercih ederiz.
              Derin Olmak: En önemli farklılıkların detaylarda gizli olduğunu biliriz. Bu nedenle, ele aldığımız konuları en ince detayına kadar inceler ve yüzeysellikten uzak durarak, her bir konunun ihtiyaç duyduğu kadar derin oluruz.
              Süreç Odaklı Olmak: Gelişimin, zamana yaygın olarak modüler yapıda tasarlanan eğitim programlarıyla hedeflenen düzeyde olacağını düşünürüz. Bu sebeple, sürekli gelişimi sağlayacak en etkin süreci, en uygun araçları kullanarak tasarlamayı tercih ederiz. Danışmanlık projelerimizde, geçmişi ve bugünü dikkate alarak yarının çözümlerini üretmeyi hedefleriz.
              Ekolojik Olmak: Attığımız her adımın ekosistemimiz içinde yarattığı etkinin farkında oluruz. Tüm rakiplerimiz de dahil olmak üzere, ekosistemimizi gözetiriz.
              Bütüncül Yaklaşım: Gelişim ve öğrenmenin ancak bütüncül bir yaklaşım ile mümkün olabileceğini biliriz. Bunun için farklı öğrenme metodolojilerini bir araya getirir, özel tasarladığımız Nar Eğitim Portalı’mızın ve deneyimsel öğrenme için inşa ettiğimiz Narköy’ümüzün tüm olanaklarından faydalanırız. Yarına dair ürettiğimiz çözümlerin, çalışanlara adaptasyonu için farklı kanallar aracılığıyla tasarladığımız eğitim programlarını kullanırız.
              
              ---- Kilometre Taslarimiz ---- 
                - 1999 Ozan Kusçu'nun Garanti Bankasi'nda ic egitmenlige baslayarak egitim ve gelisim dünyasina adim atmasi.
                - 2000 Nardane Kusçu'nun ilkögretim ögretmenliginden emekli olup LP egitimleri almaya baslamasi.
                - 2000 - 2002 Nardane Kusçu ve Ozan Kusçu'nun "LP Trainer" sürecini tamamlamasi.
                - 2002 Bireysel ve aile danismanligi hizmetleri vermek üzere Nar NLP'nin kurulmasi.
                - 2005 NAR NLP'nin, Ozan Kusçu tarafindan kurumsal egitimler boyutunun da dahil edilmesiyle Nar Egitim ve Danismanlik Merkezi'ne dönüsümü.
                - 2005 - 2008 Bankacilik sektörü satis gelisiminde Nar Egitim ve Danismanlik Merkezi'nin pazar liderligi.
                - 2008 Türkiye'nin ilk egitim odakli sürdürülebilir turizm merkezi ve organik tam çiftligi olan Narköy'ün ilk adimlannin atilmasi.
                - 2008 - 2011 Konusunda uzman danisman ve egitimcilerin katilimyla sektörel cesitliligin saglanmasi, Yönetim ve yetkinlik gelisim programlanda is kollannin açilmasi.
                - 2011 - 2014 Danismanlik projelerinin gelistirilmeye baslanmas1, Nar Egitim Portal'nin tasarlanmasi ve hayata geçirilmesi,Narköy*ün insaatinin tamamlanmasi ve hayata geçirilmesi. `
          },
          ...this.List.map((r) => {
            return {
              role: r.type == 1 ? "assistant" : "user",
              content: r.text,
            };
          }),
        ],
      };

      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer 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`,
            },
          }
        );
        this.load = false;
        this.speakText = response.data.choices[0].message.content;
        setTimeout(() => {
          this.$refs.textItem.synthesizeSpeech();
        }, 100);

        return response.data.choices[0].message.content;
      } catch (error) {
        console.error("Error sending message to OpenAI:", error);
        this.load = false;
      }
    },
  },
};
</script>


<style lang="css">
.logo {
  position: fixed;
  left: 20px !important;
  top: 20px !important;
}

.home {
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh !important;
}
</style>
